/* eslint-disable import/prefer-default-export */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:4003' : 'https://preprod.qlorem.com/api';

export const createProgramApi = createApi({
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  tagTypes: ['program'],
  reducerPath: 'createProgramApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/program/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getFormJson: builder.query({
      query: () => ({
        url: 'getformjson',
        method: 'GET',
      }),
    }),
    getAllSections: builder.query({
      query: () => ({
        url: 'allsections',
        method: 'GET',
      }),
      providesTags: ['program'],
    }),
    getDefineJson: builder.query({
      query: () => ({
        url: 'getdefinejson',
        method: 'GET',
      }),
      providesTags: ['program'],
    }),
    getAllProgs: builder.query({
      query: () => ({
        url: 'allProgs',
        method: 'GET',
      }),
      providesTags: ['program'],
    }),
    saveForm: builder.mutation({
      query: (data) => ({
        url: 'saveDraft',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['program'],
    }),
    getProgByMode: builder.query({
      query: (mode) => ({
        url: 'getByMode',
        method: 'POST',
        body: mode,
      }),
      invalidatesTags: ['program'],
    }),
    submitForm: builder.mutation({
      query: (data) => ({
        url: 'submitForm',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['program'],
    }),

    saveDefineForm: builder.mutation({
      query: (data) => ({
        url: 'allExtMtDt',
        method: 'POST',
        body: data,
        headers: {
          'content-type': 'application/json',
        },
      }),
      invalidatesTags: ['program'],
    }),

    addSection: builder.mutation({
      query: (data) => ({
        url: 'addSection',
        method: 'POST',
        body: data,
        headers: {
          'content-type': 'application/json',
        },
      }),
      invalidatesTags: ['program'],
    }),

    delete: builder.mutation({
      query: (id) => ({
        url: `delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['program'],
    }),

    deleteSection: builder.mutation({
      query: (id) => ({
        url: `delete-section/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['program'],
    }),
    updateSection: builder.mutation({
      query: (data) => ({
        url: 'update-section',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['program'],
    }),
    getCostCenterIdById: builder.query({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['program'],
    }),
    getProgramById: builder.mutation({
      query: (id) => ({
        url: 'getProgramById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['program'],
    }),
    getProgramByStatus: builder.query({
      query: (status) => ({
        url: 'getbystatus',
        method: 'POST',
        body: status,
      }),
      providesTags: ['program'],
    }),
    checkProgName: builder.mutation({
      query: (name) => ({
        url: 'getByName',
        method: 'POST',
        body: name,
      }),
      providesTags: ['program'],
    }),
  }),
});

export const {
  useGetFormJsonQuery,
  useSaveFormMutation,
  useSubmitFormMutation,
  useGetAllSectionsQuery,
  useSaveDefineFormMutation,
  useDeleteMutation,
  useAddSectionMutation,
  useDeleteSectionMutation,
  useUpdateSectionMutation,
  useGetAllProgsQuery,
  useGetDefineJsonQuery,
  useGetCostCenterIdByIdQuery,
  useGetProgramByIdMutation,
  useGetProgByModeQuery,
  useCheckProgNameMutation,
  useGetProgramByStatusQuery,
} = createProgramApi;

// Initiative Services

export const createInitiativeApi = createApi({
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  tagTypes: ['initiative'],
  reducerPath: 'createInitiativeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/initiative/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getInitJson: builder.query({
      query: () => ({
        url: 'getformjson',
        method: 'GET',
      }),
      providesTags: ['initiative'],
    }),
    getAllInitData: builder.query({
      query: () => ({
        url: 'allInit',
        method: 'GET',
      }),
      providesTags: ['initiative'],
    }),
    getInitSections: builder.query({
      query: () => ({
        url: 'allsections',
        method: 'GET',
      }),
      providesTags: ['initiative'],
    }),
    getAllValueChains: builder.query({
      query: () => ({
        url: 'allValueChains',
        method: 'GET',
      }),
      providesTags: ['initiative'],
    }),
    getAllAssets: builder.query({
      query: () => ({
        url: 'allAssets',
        method: 'GET',
      }),
      providesTags: ['initiative'],
    }),
    submitInitForm: builder.mutation({
      query: (data) => ({
        url: 'submitForm',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['initiative'],
    }),
    approveProject: builder.mutation({
      query: (data) => ({
        url: 'approve-project',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['initiative'],
    }),
    initAddSection: builder.mutation({
      query: (data) => ({
        url: 'addSection',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['initiative'],
    }),
    initDeleteSection: builder.mutation({
      query: (id) => ({
        url: `delete-section/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['initiative'],
    }),
    initUpdateSection: builder.mutation({
      query: (data) => ({
        url: 'update-section',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['initiative'],
    }),
    saveInitDefineForm: builder.mutation({
      query: (data) => ({
        url: 'allExtMtDt',
        method: 'POST',
        body: data,
        headers: {
          'content-type': 'application/json',
        },
      }),
      invalidatesTags: ['initiatives'],
    }),
    getTreeJson: builder.query({
      query: (id) => ({
        url: 'getTreeJson',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['initiatives'],
    }),
    getInitById: builder.mutation({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['initiatives'],
    }),
    getInitByStatus: builder.query({
      query: (status) => ({
        url: 'getbystatus',
        method: 'POST',
        body: status,
      }),
      providesTags: ['initiatives'],
    }),
    getProjByMode: builder.query({
      query: (mode) => ({
        url: 'getByMode',
        method: 'POST',
        body: mode,
      }),
      invalidatesTags: ['initiatives'],
    }),
    checkProjectName: builder.mutation({
      query: (name) => ({
        url: 'getByName',
        method: 'POST',
        body: name,
      }),
      providesTags: ['initiatives'],
    }),
  }),
});
export const {
  useCheckProjectNameMutation,
  useGetTreeJsonQuery,
  useGetAllInitDataQuery,
  useGetInitJsonQuery,
  useGetInitSectionsQuery,
  useGetAllValueChainsQuery,
  useGetAllAssetsQuery,
  useSubmitInitFormMutation,
  useInitAddSectionMutation,
  useInitDeleteSectionMutation,
  useInitUpdateSectionMutation,
  useSaveInitDefineFormMutation,
  useGetInitByIdMutation,
  useGetProjByModeQuery,
  useGetInitByStatusQuery,
  useApproveProjectMutation,
} = createInitiativeApi;
// People Services

export const createPeopleApi = createApi({
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  tagTypes: ['people'],
  reducerPath: 'createPeopleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/people/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllPeople: builder.query({
      query: () => ({
        url: 'allPeople',
        method: 'GET',
      }),
      providesTags: ['people'],
    }),
  }),
});
export const { useGetAllPeopleQuery } = createPeopleApi;

// File Upload
export const FileUploadApi = createApi({
  refetchOnFocus: false,
  tagTypes: ['files'],
  reducerPath: 'FileUploadApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/file/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDownoadUrls: builder.mutation({
      query: (data) => ({
        url: 'download',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['files'],
    }),
    getMultiDownoadUrls: builder.mutation({
      query: (data) => ({
        url: 'multiFileUrl',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['files'],
    }),
    getUrls: builder.mutation({
      query: (data) => ({
        url: 'get_pre_signed_urls',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['files'],
    }),

    saveDocs: builder.mutation({
      query: (data) => ({
        url: 'saveDocuments',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['files'],
    }),
    aggrBudget: builder.mutation({
      query: (data) => ({
        url: 'aggr-budget',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['files'],
    }),
    getDeleteUrl: builder.mutation({
      query: (data) => ({
        url: 'delete',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['files'],
    }),
    deleteDocs: builder.mutation({
      query: (id) => ({
        url: 'deleteDocs',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['files'],
    }),
  }),
});
export const {
  useGetDownoadUrlsMutation,
  useGetUrlsMutation,
  useSaveDocsMutation,
  useGetMultiDownoadUrlsMutation,
  useGetDeleteUrlMutation,
  useDeleteDocsMutation,
  useAggrBudgetMutation,
} = FileUploadApi;

// Requirement Change Services

export const createRcApi = createApi({
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  tagTypes: ['reqChange'],
  reducerPath: 'createRcApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/reqChange/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getRcJson: builder.query({
      query: () => ({
        url: 'getformjson',
        method: 'GET',
      }),
      providesTags: ['reqChange'],
    }),
    checkChangeName: builder.mutation({
      query: (name) => ({
        url: 'getByName',
        method: 'POST',
        body: name,
      }),
      providesTags: ['reqChange'],
    }),
    getAllRcData: builder.query({
      query: () => ({
        url: 'allReqChange',
        method: 'GET',
      }),
      providesTags: ['reqChange'],
    }),
    getRcSections: builder.query({
      query: () => ({
        url: 'allsections',
        method: 'GET',
      }),
      providesTags: ['reqChange'],
    }),
    getAllRcValueChains: builder.query({
      query: () => ({
        url: 'allValueChains',
        method: 'GET',
      }),
      providesTags: ['reqChange'],
    }),
    getAllRcAssets: builder.query({
      query: () => ({
        url: 'allAssets',
        method: 'GET',
      }),
      providesTags: ['reqChange'],
    }),
    deleteRcField: builder.mutation({
      query: (id) => ({
        url: `delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['reqChange'],
    }),
    submitRcForm: builder.mutation({
      query: (data) => ({
        url: 'submitForm',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['reqChange'],
    }),
    addRcSection: builder.mutation({
      query: (data) => ({
        url: 'addSection',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['reqChange'],
    }),
    deleteRcSection: builder.mutation({
      query: (id) => ({
        url: `delete-section/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['reqChange'],
    }),
    updateRcSection: builder.mutation({
      query: (data) => ({
        url: 'update-section',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['reqChange'],
    }),
    saveRcDefineForm: builder.mutation({
      query: (data) => ({
        url: 'allExtMtDt',
        method: 'POST',
        body: data,
        headers: {
          'content-type': 'application/json',
        },
      }),
      invalidatesTags: ['reqChange'],
    }),
    getRcTreeJson: builder.query({
      query: (data) => ({
        url: 'getNewTreeJson',
        method: 'GET',
        body: data,
        headers: {
          'content-type': 'application/json',
        },
      }),
      providesTags: ['reqChange'],
    }),
    changesByCompletedTask: builder.query({
      query: (data) => ({
        url: 'change_by_completed_task',
        method: 'GET',
        body: data,
        headers: {
          'content-type': 'application/json',
        },
      }),
      providesTags: ['reqChange'],
    }),
    getCapabilities: builder.mutation({
      query: (data) => ({
        url: 'getCapabilities',
        method: 'POST',
        body: data,
        headers: {
          'content-type': 'application/json',
        },
      }),
      providesTags: ['reqChange'],
    }),
    getChangeByMode: builder.query({
      query: (mode) => ({
        url: 'getByMode',
        method: 'POST',
        body: mode,
      }),
      invalidatesTags: ['reqChange'],
    }),
    getChangeById: builder.mutation({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['reqChange'],
    }),
    getChangeByProjId: builder.query({
      query: (id) => ({
        url: 'getByProjectId',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['reqChange'],
    }),
  }),
});
export const {
  useGetRcJsonQuery,
  useAddRcSectionMutation,
  useDeleteRcSectionMutation,
  useGetAllRcAssetsQuery,
  useSubmitRcFormMutation,
  useUpdateRcSectionMutation,
  useSaveRcDefineFormMutation,
  useDeleteRcFieldMutation,
  useGetCapabilitiesMutation,
  useGetAllRcDataQuery,
  useGetAllRcValueChainsQuery,
  useGetRcSectionsQuery,
  useGetRcTreeJsonQuery,
  useGetChangeByModeQuery,
  useGetChangeByIdMutation,
  useGetChangeByProjIdQuery,
  useChangesByCompletedTaskQuery,
  useCheckChangeNameMutation,
} = createRcApi;

// Language

export const createLangApi = createApi({
  refetchOnFocus: false,
  tagTypes: ['lang'],
  reducerPath: 'createLangApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/language/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getTransJson: builder.query({
      query: (langs) => ({
        url: 'getTrans',
        method: 'POST',
        body: langs,
      }),
      providesTags: ['lang'],
    }),
    getTranslationJson: builder.mutation({
      query: (langs) => ({
        url: 'getTrans',
        method: 'POST',
        body: langs,
      }),
      providesTags: ['lang'],
    }),

    getLangNames: builder.query({
      query: () => ({
        url: 'getLangNames',
        method: 'GET',
      }),
      providesTags: ['lang'],
    }),
    addLang: builder.mutation({
      query: (data) => ({
        url: 'addLang',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['lang'],
    }),
  }),
});

export const {
  useGetTransJsonQuery,
  useAddLangMutation,
  useGetLangNamesQuery,
  useGetTranslationJsonMutation,
} = createLangApi;

// Application Services
export const createApplicationApi = createApi({
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  tagTypes: ['application'],
  reducerPath: 'createApplicationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/application/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAppJson: builder.query({
      query: () => ({
        url: 'extMtDt',
        method: 'GET',
      }),
      providesTags: ['application'],
    }),
    checkAppName: builder.mutation({
      query: (name) => ({
        url: 'getByName',
        method: 'POST',
        body: name,
      }),
      providesTags: ['application'],
    }),
    submitApplication: builder.mutation({
      query: (data) => ({
        url: 'submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['application'],
    }),
    updateApplication: builder.mutation({
      query: (data) => ({
        url: 'update',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['application'],
    }),
    fetchAllApplication: builder.query({
      query: (id) => ({
        url: 'fetchAll',
        method: 'GET',
      }),
      providesTags: ['application'],
    }),
    getApplicationById: builder.mutation({
      query: (id) => ({
        url: 'getApplicationById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['application'],
    }),
    getApps: builder.mutation({
      query: (data) => ({
        url: 'getApps',
        method: 'POST',
        body: data,
      }),
      providesTags: ['application'],
    }),
    getAppByMode: builder.query({
      query: (mode) => ({
        url: 'getByMode',
        method: 'POST',
        body: mode,
      }),
      invalidatesTags: ['application'],
    }),
    getAppByStatus: builder.query({
      query: (status) => ({
        url: 'getbystatus',
        method: 'POST',
        body: status,
      }),
      invalidatesTags: ['application'],
    }),
    getAppById: builder.mutation({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['application'],
    }),
  }),
});
export const {
  useGetAppJsonQuery,
  useSubmitApplicationMutation,
  useGetAppsMutation,
  useFetchAllApplicationQuery,
  useGetApplicationByIdMutation,
  useUpdateApplicationMutation,
  useGetAppByModeQuery,
  useCheckAppNameMutation,
  useGetAppByStatusQuery,
  useGetAppByIdMutation,
} = createApplicationApi;

// Business Services
export const createBusinessApi = createApi({
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  tagTypes: ['business', 'business-twin'],
  reducerPath: 'createBusinessApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/business/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getBusinessJson: builder.query({
      query: () => ({
        url: 'extMtDt',
        method: 'GET',
      }),
      providesTags: ['business'],
    }),
    checkBusinessName: builder.mutation({
      query: (name) => ({
        url: 'getByName',
        method: 'POST',
        body: name,
      }),
      providesTags: ['business'],
    }),
    checkBusinessCode: builder.mutation({
      query: (code) => ({
        url: 'getByCode',
        method: 'POST',
        body: code,
      }),
      providesTags: ['business'],
    }),
    submitBusiness: builder.mutation({
      query: (data) => ({
        url: 'submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['business'],
    }),
    fetchAllBusiness: builder.query({
      query: (id) => ({
        url: 'fetchAll',
        method: 'GET',
      }),
      providesTags: ['business'],
    }),
    getBusinessById: builder.mutation({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['business'],
    }),
    getBusinessByStatus: builder.query({
      query: (status) => ({
        url: 'getbystatus',
        method: 'POST',
        body: status,
      }),
      invalidatesTags: ['business'],
    }),
    businessTwinById: builder.mutation({
      query: (id) => ({
        url: 'businessTwinById',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['business-twin'],
    }),
    submitBusinessTwin: builder.mutation({
      query: (data) => ({
        url: 'submitBusinessTwin',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['business-twin'],
    }),
  }),
});
export const {
  useGetBusinessJsonQuery,
  useGetBusinessByIdMutation,
  useFetchAllBusinessQuery,
  useCheckBusinessNameMutation,
  useSubmitBusinessMutation,
  useGetBusinessByStatusQuery,
  useBusinessTwinByIdMutation,
  useSubmitBusinessTwinMutation,
  useCheckBusinessCodeMutation,
} = createBusinessApi;

// Vendor Services
export const createVendorApi = createApi({
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  tagTypes: ['vendor', 'vendor-twin'],
  reducerPath: 'createVendorApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/vendor/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getVendorJson: builder.query({
      query: () => ({
        url: 'extMtDt',
        method: 'GET',
      }),
      providesTags: ['vendor'],
    }),
    checkVendorName: builder.mutation({
      query: (name) => ({
        url: 'getByName',
        method: 'POST',
        body: name,
      }),
      providesTags: ['vendor'],
    }),
    submitVendor: builder.mutation({
      query: (data) => ({
        url: 'submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['vendor'],
    }),
    fetchAllVendor: builder.query({
      query: (id) => ({
        url: 'fetchAll',
        method: 'GET',
      }),
      providesTags: ['vendor'],
    }),
    getVendorById: builder.mutation({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['vendor'],
    }),
    vendorTwinById: builder.mutation({
      query: (id) => ({
        url: 'vendorTwinById',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['vendor-twin'],
    }),
    submitVendorTwin: builder.mutation({
      query: (data) => ({
        url: 'submitVendorTwin',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['vendor-twin'],
    }),
    getVendorByStatus: builder.query({
      query: (status) => ({
        url: 'getbystatus',
        method: 'POST',
        body: status,
      }),
      invalidatesTags: ['vendor'],
    }),
  }),
});
export const {
  useGetVendorJsonQuery,
  useCheckVendorNameMutation,
  useSubmitVendorMutation,
  useGetVendorByIdMutation,
  useFetchAllVendorQuery,
  useVendorTwinByIdMutation,
  useSubmitVendorTwinMutation,
  useGetVendorByStatusQuery,
} = createVendorApi;

// Customer Services
export const createCustomerApi = createApi({
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  tagTypes: ['customer', 'customer-twin'],
  reducerPath: 'createCustomerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/customer/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCustomerJson: builder.query({
      query: () => ({
        url: 'extMtDt',
        method: 'GET',
      }),
      providesTags: ['customer'],
    }),
    checkCustomerName: builder.mutation({
      query: (name) => ({
        url: 'getByName',
        method: 'POST',
        body: name,
      }),
      providesTags: ['customer'],
    }),
    submitCustomer: builder.mutation({
      query: (data) => ({
        url: 'submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['customer'],
    }),
    fetchAllCustomer: builder.query({
      query: (id) => ({
        url: 'fetchAll',
        method: 'GET',
      }),
      providesTags: ['customer'],
    }),
    getCustomerById: builder.mutation({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['customer'],
    }),
    customerTwinById: builder.mutation({
      query: (id) => ({
        url: 'customerTwinById',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['customer-twin'],
    }),
    submitCustomerTwin: builder.mutation({
      query: (data) => ({
        url: 'submitCustomerTwin',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['customer-twin'],
    }),
    getCustomerByStatus: builder.query({
      query: (status) => ({
        url: 'getbystatus',
        method: 'POST',
        body: status,
      }),
      invalidatesTags: ['customer'],
    }),
  }),
});
export const {
  useGetCustomerJsonQuery,
  useCheckCustomerNameMutation,
  useSubmitCustomerMutation,
  useGetCustomerByIdMutation,
  useFetchAllCustomerQuery,
  useCustomerTwinByIdMutation,
  useSubmitCustomerTwinMutation,
  useGetCustomerByStatusQuery,
} = createCustomerApi;

// RIsk Services
export const createRiskApi = createApi({
  refetchOnMountOrArgChange: true,
  tagTypes: ['risk', 'riskMitigation'],
  reducerPath: 'createRiskApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/risk/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getRiskJson: builder.query({
      query: () => ({
        url: 'getExtMtDt',
        method: 'GET',
      }),
      providesTags: ['risk'],
    }),
    checkRiskName: builder.mutation({
      query: (name) => ({
        url: 'getByName',
        method: 'POST',
        body: name,
      }),
      providesTags: ['risk'],
    }),
    getRiskMitigationJson: builder.query({
      query: () => ({
        url: 'getMitigationExtMtDt',
        method: 'GET',
      }),
      providesTags: ['risk'],
    }),
    getRmById: builder.query({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['riskMitigation'],
    }),
    fetchAllRiskMitigation: builder.query({
      query: (id) => ({
        url: 'fetchAllRm',
        method: 'GET',
      }),
      providesTags: ['riskMitigation'],
    }),
    submitRisk: builder.mutation({
      query: (data) => ({
        url: 'submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['risk'],
    }),
    submitRM: builder.mutation({
      query: (data) => ({
        url: 'submitRM',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['riskMitigation'],
    }),
    deleteRm: builder.mutation({
      query: (data) => ({
        url: 'deleteRm',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['riskMitigation'],
    }),
    fetchAllRisk: builder.query({
      query: (id) => ({
        url: 'fetchAll',
        method: 'GET',
      }),
      providesTags: ['risk'],
    }),
    getRiskByMode: builder.query({
      query: (mode) => ({
        url: 'getByMode',
        method: 'POST',
        body: mode,
      }),
      providesTags: ['risk'],
    }),
    getRiskByStatus: builder.query({
      query: (status) => ({
        url: 'getbystatus',
        method: 'POST',
        body: status,
      }),
      providesTags: ['risk'],
    }),
    getRiskById: builder.mutation({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['risk'],
    }),
    findRMById: builder.query({
      query: (payload) => ({
        url: 'findByIdRM',
        method: 'POST',
        body: payload,
      }),
      providesTags: ['riskMitigation'],
    }),
  }),
});
export const {
  useGetRiskJsonQuery,
  useGetRiskMitigationJsonQuery,
  useGetRmByIdQuery,
  useFetchAllRiskMitigationQuery,
  useGetRiskByIdMutation,
  useSubmitRMMutation,
  useSubmitRiskMutation,
  useFetchAllRiskQuery,
  useGetRiskByModeQuery,
  useDeleteRmMutation,
  useCheckRiskNameMutation,
  useGetRiskByStatusQuery,
  useFindRMByIdQuery,
} = createRiskApi;

// Benefit services
export const createBenefitApi = createApi({
  // refetchOnFocus: true,
  reducerPath: 'createBenefitApi',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/benefits/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['benefit', 'benefitTP'],
  endpoints: (builder) => ({
    getBenefitJson: builder.query({
      query: () => ({
        url: 'extMtDt',
        method: 'GET',
      }),
      providesTags: ['benefit'],
    }),
    checkBenefitName: builder.mutation({
      query: (name) => ({
        url: 'getByName',
        method: 'POST',
        body: name,
      }),
      providesTags: ['benefit'],
    }),
    getBenefitTrackingPointJson: builder.query({
      query: () => ({
        url: 'extMtDtTrackingPoint',
        method: 'GET',
      }),
      providesTags: ['benefit'],
    }),
    getTrackingPointById: builder.query({
      query: (id) => ({
        url: 'getTrackingPointById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['benefitTP'],
    }),
    findBTById: builder.query({
      query: (payload) => ({
        url: 'findByIdBT',
        method: 'POST',
        body: payload,
      }),
      providesTags: ['benefitTP'],
    }),
    fetchAllTrackingPoints: builder.query({
      query: (id) => ({
        url: 'fetchAllBfT',
        method: 'GET',
      }),
      providesTags: ['benefitTP'],
    }),
    fetchAllBenefit: builder.query({
      query: (id) => ({
        url: 'fetchAll',
        method: 'GET',
      }),
      providesTags: ['benefit'],
    }),
    submitBenefit: builder.mutation({
      query: (data) => ({
        url: 'submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['benefit'],
    }),
    submitBenefitTp: builder.mutation({
      query: (data) => ({
        url: 'submitTp',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['benefitTP'],
    }),
    getBenefitById: builder.mutation({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['benefit'],
    }),
    getBenefitByMode: builder.query({
      query: (mode) => ({
        url: 'getByMode',
        method: 'POST',
        body: mode,
      }),
      invalidatesTags: ['benefit'],
    }),
    getBenefitByStatus: builder.query({
      query: (status) => ({
        url: 'getbystatus',
        method: 'POST',
        body: status,
      }),
      invalidatesTags: ['benefit'],
    }),
    deleteTp: builder.mutation({
      query: (data) => ({
        url: 'deleteTp',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['benefitTP'],
    }),
  }),
});
export const {
  useGetBenefitJsonQuery,
  useGetBenefitTrackingPointJsonQuery,
  useGetTrackingPointByIdQuery,
  useFetchAllTrackingPointsQuery,
  useSubmitBenefitMutation,
  useSubmitBenefitTpMutation,
  useFetchAllBenefitQuery,
  useGetBenefitByIdMutation,
  useGetBenefitByModeQuery,
  useDeleteTpMutation,
  useCheckBenefitNameMutation,
  useGetBenefitByStatusQuery,
  useFindBTByIdQuery,
} = createBenefitApi;

// Business Units services
export const createBusinessUnitApi = createApi({
  refetchOnFocus: false,
  tagTypes: ['business_unit'],
  reducerPath: 'createBusinessUnitApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/business_unit/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getBuById: builder.query({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['business_unit'],
    }),

    getBuByOrgId: builder.query({
      query: (id) => ({
        url: 'getByOrgId',
        method: 'POST',
        body: id,
      }),
      providesTags: ['business_unit'],
    }),
    getBuByCode: builder.mutation({
      query: (id) => ({
        url: 'findByCode',
        method: 'POST',
        body: id,
      }),
      providesTags: ['business_unit'],
    }),
    deleteBuById: builder.mutation({
      query: (id) => ({
        url: 'delete',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['business_unit'],
    }),
    fetchAllBu: builder.query({
      query: () => ({
        url: 'fetchAll',
        method: 'GET',
      }),
      providesTags: ['business_unit'],
    }),
    submitBu: builder.mutation({
      query: (data) => ({
        url: 'submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['business_unit'],
    }),
  }),
});
export const {
  useGetBuByIdQuery,
  useFetchAllBuQuery,
  useGetBuByCodeMutation,
  useDeleteBuByIdMutation,
  useSubmitBuMutation,
  useGetBuByOrgIdQuery,
} = createBusinessUnitApi;

// department  services
export const createDepartmentApi = createApi({
  refetchOnFocus: false,
  tagTypes: ['department'],
  reducerPath: 'createDepartmentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/department/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDepById: builder.query({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['department'],
    }),
    getDepByBuId: builder.query({
      query: (id) => ({
        url: 'getByBuId',
        method: 'POST',
        body: id,
      }),
      providesTags: ['department'],
    }),
    getDepByCode: builder.mutation({
      query: (id) => ({
        url: 'findByCode',
        method: 'POST',
        body: id,
      }),
      providesTags: ['department'],
    }),
    deleteDepById: builder.mutation({
      query: (id) => ({
        url: 'delete',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['department'],
    }),
    fetchAllDeps: builder.query({
      query: () => ({
        url: 'fetchAll',
        method: 'GET',
      }),
      providesTags: ['department'],
    }),
    submitDeps: builder.mutation({
      query: (data) => ({
        url: 'submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['department'],
    }),
    checkDepartmentCode: builder.mutation({
      query: (code) => ({
        url: 'getByCode',
        method: 'POST',
        body: code,
      }),
      providesTags: ['department'],
    }),
  }),
});
export const {
  useGetDepByIdQuery,
  useFetchAllDepsQuery,
  useDeleteDepByIdMutation,
  useSubmitDepsMutation,
  useGetDepByCodeMutation,
  useGetDepByBuIdQuery,
  useCheckDepartmentCodeMutation,
} = createDepartmentApi;

// Cost Center  services
export const createCostCenterApi = createApi({
  refetchOnFocus: false,
  tagTypes: ['costCenter'],
  reducerPath: 'createCostCenterApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/costCenter/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCCById: builder.query({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['costCenter'],
    }),
    deleteCCById: builder.mutation({
      query: (id) => ({
        url: 'delete',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['costCenter'],
    }),
    fetchAllCC: builder.query({
      query: () => ({
        url: 'fetchAll',
        method: 'GET',
      }),
      providesTags: ['costCenter'],
    }),
    submitCC: builder.mutation({
      query: (data) => ({
        url: 'submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['costCenter'],
    }),
    checkCostCenterCode: builder.mutation({
      query: (code) => ({
        url: 'getByCode',
        method: 'POST',
        body: code,
      }),
      providesTags: ['costCenter'],
    }),
  }),
});
export const {
  useSubmitCCMutation,
  useFetchAllCCQuery,
  useGetCCByIdQuery,
  useDeleteCCByIdMutation,
  useCheckCostCenterCodeMutation,
} = createCostCenterApi;

// Tenant services
export const createTenantApi = createApi({
  refetchOnFocus: false,
  tagTypes: ['Tenant'],
  reducerPath: 'createTenantApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/tenant/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getTenantById: builder.query({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['Tenant'],
    }),
    fetchTenant: builder.query({
      query: () => ({
        url: 'fetchInfo',
        method: 'GET',
      }),
      providesTags: ['Tenant'],
    }),
    submitTenant: builder.mutation({
      query: (data) => ({
        url: 'submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Tenant'],
    }),

  }),
});
export const {
  useFetchTenantQuery,
  useSubmitTenantMutation,
  useGetTenantByIdQuery,
} = createTenantApi;

// Organization  services
export const createOrganizationApi = createApi({
  refetchOnFocus: false,
  tagTypes: ['organization'],
  reducerPath: 'createOrganizationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/organization/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getOrgById: builder.query({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['organization'],
    }),
    getOrgByCode: builder.mutation({
      query: (id) => ({
        url: 'findByCode',
        method: 'POST',
        body: id,
      }),
      providesTags: ['organization'],
    }),
    deleteOrgById: builder.mutation({
      query: (id) => ({
        url: 'delete',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['organization'],
    }),
    fetchAllOrg: builder.query({
      query: () => ({
        url: 'fetchAll',
        method: 'GET',
      }),
      providesTags: ['organization'],
    }),
    updateOrg: builder.mutation({
      query: (data) => ({
        url: 'update',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['organization'],
    }),
    submitOrg: builder.mutation({
      query: (data) => ({
        url: 'submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['organization'],
    }),
    checkOrgCode: builder.mutation({
      query: (code) => ({
        url: 'getByCode',
        method: 'POST',
        body: code,
      }),
      providesTags: ['organization'],
    }),
  }),
});
export const {
  useGetOrgByIdQuery,
  useFetchAllOrgQuery,
  useGetOrgByCodeMutation,
  useUpdateOrgMutation,
  useSubmitOrgMutation,
  useDeleteOrgByIdMutation,
  useCheckOrgCodeMutation,
} = createOrganizationApi;

// Task Services
export const createTaskApi = createApi({
  refetchOnFocus: false,
  // refetchOnReconnect: true,
  // refetchOnMountOrArgChange: true,
  // keepUnusedDataFor: 0.01,
  tagTypes: ['task-data'],
  reducerPath: 'createTaskApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/task/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllTask: builder.query({
      query: () => ({
        url: 'fetchAll',
        method: 'GET',
      }),
      providesTags: ['task-data'],
    }),
    updateStatus: builder.mutation({
      query: (data) => ({
        url: 'update-status',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['task-data'],
    }),
    taskByUser: builder.query({
      query: (id) => ({
        url: 'taskByUser',
        method: 'POST',
        body: id,
      }),
      providesTags: ['task-data'],
    }),
    taskByChange: builder.query({
      query: (id) => ({
        url: 'taskByChange',
        method: 'POST',
        body: id,
      }),
      providesTags: ['task-data'],
    }),
    taskByChangeM: builder.mutation({
      query: (id) => ({
        url: 'taskByChange',
        method: 'POST',
        body: id,
      }),
      providesTags: ['task-data'],
    }),
  }),
});
export const {
  useGetAllTaskQuery,
  useUpdateStatusMutation,
  useTaskByUserQuery,
  useTaskByChangeQuery,
  useTaskByChangeMMutation,
} = createTaskApi;

// Application Services
export const createTwinApi = createApi({
  refetchOnFocus: false,
  tagTypes: ['twin'],
  reducerPath: 'createTwinApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/twin/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    findTwins: builder.mutation({
      query: (data) => ({
        url: 'findTwin',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['twin'],
    }),
    findProcessTwins: builder.mutation({
      query: (data) => ({
        url: 'findProcessTwin',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['twin'],
    }),

    getDynDto: builder.query({
      query: (id) => ({
        url: 'dynDto',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['twin'],
    }),
    lockStatus: builder.mutation({
      query: (data) => ({
        url: 'lockStatus',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['twin'],
    }),
    twinByCapab: builder.mutation({
      query: (data) => ({
        url: 'twinsByCapab',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['twin'],
    }),
    twinCaps: builder.mutation({
      query: (id) => ({
        url: 'dynDto',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['twin'],
    }),
  }),
});
export const {
  useFindTwinsMutation,
  useLockStatusMutation,
  useGetDynDtoQuery,
  useFindProcessTwinsMutation,
  useTwinByCapabMutation,
  useTwinCapsMutation,
} = createTwinApi;

// App Twin Services
export const createAppTwinApi = createApi({
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  tagTypes: ['app-twin'],
  reducerPath: 'createAppTwinApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/app-twin/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    appTwinByIds: builder.mutation({
      query: (id) => ({
        url: 'twinById',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['app-twin'],
    }),
    appTwinById: builder.query({
      query: (id) => ({
        url: 'twinById',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['app-twin'],
    }),
    fetchAllTwin: builder.query({
      query: (id) => ({
        url: 'fetchAll',
        method: 'GET',
      }),
      invalidatesTags: ['app-twin'],
    }),
    submitTwin: builder.mutation({
      query: (data) => ({
        url: 'submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['app-twin'],
    }),
    submitProcessTwin: builder.mutation({
      query: (data) => ({
        url: 'submitProcessTwin',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['app-twin'],
    }),
    submitProcessStepTwin: builder.mutation({
      query: (data) => ({
        url: 'submitProcessStepTwin',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['app-twin'],
    }),
    stepsTwinByProcesstwinId: builder.mutation({
      query: (id) => ({
        url: 'stepTwinByTwinId',
        method: 'POST',
        body: id,
      }),
      providesTags: ['twin'],
    }),
  }),
});
export const {
  useAppTwinByIdQuery,
  useAppTwinByIdsMutation,
  useSubmitTwinMutation,
  useFetchAllTwinQuery,
  useSubmitProcessTwinMutation,
  useSubmitProcessStepTwinMutation,
  useStepsTwinByProcesstwinIdMutation,
} = createAppTwinApi;

// Dyn DTO Services
export const createDynDtoApi = createApi({
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  tagTypes: ['dynDto'],
  reducerPath: 'createDynDtoApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/twin/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    dynDto: builder.query({
      query: (id) => ({
        url: 'dynDto',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['dynDto'],
    }),
    dynDtoOnly: builder.mutation({
      query: (id) => ({
        url: 'dynDto',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['dynDto'],
    }),
    // submitTwin: builder.mutation({
    //   query: (data) => ({
    //     url: 'submit',
    //     method: 'POST',
    //     body: data,
    //   }),
    //   invalidatesTags: ['app-twin'],
    // }),
  }),
});
export const { useDynDtoQuery, useDynDtoOnlyMutation } = createDynDtoApi;

// Auth Services
export const createAuthApi = createApi({
  refetchOnFocus: false,
  tagTypes: ['auth'],
  reducerPath: 'createAuthApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/auth`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    checkAuth: builder.mutation({
      query: (data) => ({
        url: 'signIn',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['auth'],
    }),
    checkAuth0: builder.mutation({
      query: (data) => ({
        url: 'auth0_signIn',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['auth'],
    }),
  }),
});
export const { useCheckAuthMutation, useCheckAuth0Mutation } = createAuthApi;

// Epic Services

export const createEpicApi = createApi({
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  tagTypes: ['Epic'],
  reducerPath: 'createEpicApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/epic/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getEpicFormJson: builder.query({
      query: () => ({
        url: 'getformjson',
        method: 'GET',
      }),
    }),
    checkEpicName: builder.mutation({
      query: (name) => ({
        url: 'getByName',
        method: 'POST',
        body: name,
      }),
      providesTags: ['Epic'],
    }),

    getAllEpics: builder.query({
      query: () => ({
        url: 'allEpics',
        method: 'GET',
      }),
      providesTags: ['Epic'],
    }),

    getEpicByMode: builder.query({
      query: (mode) => ({
        url: 'getByMode',
        method: 'POST',
        body: mode,
      }),
      invalidatesTags: ['Epic'],
    }),
    getEpicByChange: builder.query({
      query: (id) => ({
        url: 'epicByChange',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['Epic'],
    }),
    submitEpic: builder.mutation({
      query: (data) => ({
        url: 'submitForm',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Epic'],
    }),

    getEpicById: builder.mutation({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['Epic'],
    }),
    getEpicInfo: builder.query({
      query: (id) => ({
        url: 'epicInfo',
        method: 'POST',
        body: id,
      }),
      providesTags: ['Epic'],
    }),
    getEpicInfoM: builder.mutation({
      query: (id) => ({
        url: 'epicInfo',
        method: 'POST',
        body: id,
      }),
      providesTags: ['Epic'],
    }),
  }),
});

export const {
  useGetAllEpicsQuery,
  useGetEpicByIdMutation,
  useGetEpicByModeQuery,
  useGetEpicFormJsonQuery,
  useSubmitEpicMutation,
  useGetEpicByChangeQuery,
  useGetEpicInfoQuery,
  useCheckEpicNameMutation,
  useGetEpicInfoMMutation,
} = createEpicApi;

// Epic Task Service
export const createEpicTaskApi = createApi({
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  tagTypes: ['EpicTask'],
  reducerPath: 'createEpicTaskApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/epic/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getEpicTaskExtMtDt: builder.query({
      query: () => ({
        url: 'getEpicTaskExtMtDt',
        method: 'GET',
      }),
    }),
    checkEpicTaskName: builder.mutation({
      query: (name) => ({
        url: 'getEpicTaskByName',
        method: 'POST',
        body: name,
      }),
      providesTags: ['EpicTask'],
    }),
    getAllEpicTask: builder.query({
      query: () => ({
        url: 'getAllEpicTask',
        method: 'GET',
      }),
      providesTags: ['EpicTask'],
    }),
    getEpicTaskByMode: builder.query({
      query: (mode) => ({
        url: 'getEpicTaskByMode',
        method: 'POST',
        body: mode,
      }),
      invalidatesTags: ['EpicTask'],
    }),
    submitEpicTask: builder.mutation({
      query: (data) => ({
        url: 'submitEpicTask',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['EpicTask'],
    }),
    getEpicTaskById: builder.mutation({
      query: (id) => ({
        url: 'getEpicTaskById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['EpicTask'],
    }),
    deleteEpicTask: builder.mutation({
      query: (data) => ({
        url: 'deleteEpicTask',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['EpicTask'],
    }),
  }),
});

export const {
  useGetAllEpicTaskQuery,
  useGetEpicTaskExtMtDtQuery,
  useGetEpicTaskByIdMutation,
  useGetEpicTaskByModeQuery,
  useSubmitEpicTaskMutation,
  useCheckEpicTaskNameMutation,
  useDeleteEpicTaskMutation,
} = createEpicTaskApi;

// Process Services

export const createProcessApi = createApi({
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  tagTypes: ['Process', 'process_step'],
  reducerPath: 'createProcessApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/process/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProcessJson: builder.query({
      query: () => ({
        url: 'getProcessJson',
        method: 'GET',
      }),
    }),
    getProcessStepJson: builder.query({
      query: () => ({
        url: 'getProcessStepJson',
        method: 'GET',
      }),
    }),
    stepsByProcessId: builder.mutation({
      query: (id) => ({
        url: 'stepsByProcessId',
        method: 'POST',
        body: id,
      }),
      providesTags: ['process_step'],
    }),
    checkProcessStepName: builder.mutation({
      query: (name) => ({
        url: 'getByProcessStepName',
        method: 'POST',
        body: name,
      }),
      providesTags: ['process_step'],
    }),
    checkProcessName: builder.mutation({
      query: (name) => ({
        url: 'getByProcessName',
        method: 'POST',
        body: name,
      }),
      providesTags: ['Process'],
    }),

    getAllProcess: builder.query({
      query: () => ({
        url: 'allProcess',
        method: 'GET',
      }),
      providesTags: ['Process'],
    }),

    getAllProcessStep: builder.query({
      query: () => ({
        url: 'allProcessStep',
        method: 'GET',
      }),
      providesTags: ['process_step'],
    }),

    getProcessByMode: builder.query({
      query: (mode) => ({
        url: 'getByMode',
        method: 'POST',
        body: mode,
      }),
      invalidatesTags: ['Process'],
    }),
    getProcessByStatus: builder.query({
      query: (status) => ({
        url: 'getbystatus',
        method: 'POST',
        body: status,
      }),
      invalidatesTags: ['Process'],
    }),
    getProcessByChange: builder.query({
      query: (id) => ({
        url: 'epicByChange',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['Process'],
    }),
    submitProcess: builder.mutation({
      query: (data) => ({
        url: 'submitProcess',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Process'],
    }),
    submitProcessStep: builder.mutation({
      query: (data) => ({
        url: 'submitProcessStep',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['process_step'],
    }),
    getProcessById: builder.mutation({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['Process'],
    }),
    stepsById: builder.mutation({
      query: (id) => ({
        url: 'stepsById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['process_step'],
    }),
  }),
});

export const {
  useCheckProcessNameMutation,
  useCheckProcessStepNameMutation,
  useGetAllProcessQuery,
  useGetProcessByChangeQuery,
  useGetProcessByIdMutation,
  useGetProcessByModeQuery,
  useGetProcessJsonQuery,
  useGetProcessStepJsonQuery,
  useGetAllProcessStepQuery,
  useSubmitProcessStepMutation,
  useSubmitProcessMutation,
  useStepsByProcessIdMutation,
  useGetProcessByStatusQuery,
  useStepsByIdMutation,
} = createProcessApi;

// extMtDt Services
export const createExtMtDtApi = createApi({
  refetchOnFocus: false,
  tagTypes: ['extMtDt'],
  reducerPath: 'createExtMtDtApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/extMtDt`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getExtMtDt: builder.mutation({
      query: (data) => ({
        url: 'getExtMtDt',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['extMtDt'],
    }),
    getExtMtDtQuery: builder.query({
      query: (data) => ({
        url: 'getExtMtDt',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['extMtDt'],
    }),
    submitExtMtDt: builder.mutation({
      query: (data) => ({
        url: 'submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['extMtDt'],
    }),
    deleteExtMtDt: builder.mutation({
      query: (id) => ({
        url: 'delete',
        method: 'POST',
        body: id,
      }),
      providesTags: ['extMtDt'],
    }),
  }),
});
export const {
  useGetExtMtDtMutation,
  useGetExtMtDtQueryQuery,
  useSubmitExtMtDtMutation,
  useDeleteExtMtDtMutation,
} = createExtMtDtApi;

// Enum Services
export const createEnumApi = createApi({
  refetchOnFocus: false,
  tagTypes: ['enums'],
  reducerPath: 'createEnumApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/enumMaster`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    submitEnums: builder.mutation({
      query: (data) => ({
        url: 'submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['enums'],
    }),
    submitAllEnums: builder.mutation({
      query: (data) => ({
        url: 'submitAll',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['enums'],
    }),
    enumsByField: builder.mutation({
      query: (data) => ({
        url: 'getByFieldName',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['enums'],
    }),
  }),
});
export const {
  useSubmitEnumsMutation,
  useSubmitAllEnumsMutation,
  useEnumsByFieldMutation,
} = createEnumApi;

// Notification  services
export const createNotificationApi = createApi({
  refetchOnFocus: false,
  tagTypes: ['notifications'],
  reducerPath: 'createNotificationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/notifications/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getNotificationsById: builder.query({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['notifications'],
    }),

    updateView: builder.mutation({
      query: (data) => ({
        url: 'update-view',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['notifications'],
    }),
  }),
});
export const { useGetNotificationsByIdQuery, useUpdateViewMutation } = createNotificationApi;

// Status Servcies
export const createStatusApi = createApi({
  refetchOnFocus: false,
  tagTypes: ['status'],
  reducerPath: 'createStatusApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/status/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    updateEpicStatus: builder.mutation({
      query: (id) => ({
        url: 'update-epic-status',
        method: 'POST',
        body: id,
      }),
      providesTags: ['status'],
    }),
  }),
});
export const { useUpdateEpicStatusMutation } = createStatusApi;

// Dashboard Servcies
export const createDashboardApi = createApi({
  refetchOnFocus: false,
  tagTypes: ['dashboard'],
  reducerPath: 'createDashboardApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/dashboard/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDoughntCount: builder.query({
      query: (id) => ({
        url: 'doughnutCount',
        method: 'GET',
        body: id,
      }),
      providesTags: ['dashboard'],
    }),
  }),
});
export const { useGetDoughntCountQuery } = createDashboardApi;

// Common Servcies
export const createCommonApi = createApi({
  refetchOnFocus: false,
  tagTypes: ['common'],
  reducerPath: 'createCommonApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/common/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    solSpecsProject: builder.mutation({
      query: (id) => ({
        url: 'sol-specs-project',
        method: 'POSt',
        body: id,
      }),
      providesTags: ['common'],
    }),
    solSpecsChange: builder.mutation({
      query: (id) => ({
        url: 'sol-specs-change',
        method: 'POSt',
        body: id,
      }),
      providesTags: ['common'],
    }),
    getSummaryLog: builder.query({
      query: ({ id, type }) => ({
        url: 'summaryLog',
        method: 'POST',
        body: { id, type },
      }),
      providesTags: ['common'],
    }),
    saveSummaryLog: builder.mutation({
      query: (data) => ({
        url: 'saveSummaryLog',
        method: 'POST',
        body: data,
        headers: {
          'content-type': 'application/json',
        },
      }),
      invalidatesTags: ['common'],
    }),
    topRisk: builder.mutation({
      query: (data) => ({
        url: 'topRisk',
        method: 'POST',
        body: data,
        headers: {
          'content-type': 'application/json',
        },
      }),
      invalidatesTags: ['common'],
    }),
    riskIndex: builder.mutation({
      query: (data) => ({
        url: 'riskIndex',
        method: 'POST',
        body: data,
        headers: {
          'content-type': 'application/json',
        },
      }),
      invalidatesTags: ['common'],
    }),
    riskMatrix: builder.mutation({
      query: (data) => ({
        url: 'risk-matrix',
        method: 'POST',
        body: data,
        headers: {
          'content-type': 'application/json',
        },
      }),
      invalidatesTags: ['common'],
    }),
    overAllStatus: builder.query({
      query: ({ id, type }) => ({
        url: 'overall-status',
        method: 'POST',
        body: { id, type },
      }),
      providesTags: ['common'],
    }),
    overAllStatusValue: builder.query({
      query: ({ id, type }) => ({
        url: 'overall-status-value',
        method: 'POST',
        body: { id, type },
      }),
      providesTags: ['common'],
    }),
    submitOverAllStatus: builder.mutation({
      query: (data) => ({
        url: 'submit-overall-status',
        method: 'POST',
        body: data,
        headers: {
          'content-type': 'application/json',
        },
      }),
      invalidatesTags: ['common'],
    }),
    ganttChart: builder.mutation({
      query: (data) => ({
        url: 'gantt-chart-data',
        method: 'POST',
        body: data,
        headers: {
          'content-type': 'application/json',
        },
      }),
      invalidatesTags: ['common'],
    }),
    varianceChartTable: builder.mutation({
      query: (data) => ({
        url: 'variance-chart-table',
        method: 'POST',
        body: data,
        headers: {
          'content-type': 'application/json',
        },
      }),
      invalidatesTags: ['common'],
    }),
    varianceChart: builder.mutation({
      query: (data) => ({
        url: 'variance-chart-data',
        method: 'POST',
        body: data,
        headers: {
          'content-type': 'application/json',
        },
      }),
      invalidatesTags: ['common'],
    }),
    savePeopleDelegation: builder.mutation({
      query: (data) => ({
        url: 'save-people-delegation',
        method: 'POST',
        body: data,
        headers: {
          'content-type': 'application/json',
        },
      }),
      invalidatesTags: ['common'],
    }),
    submitStatusConfig: builder.mutation({
      query: (data) => ({
        url: 'submit-status-config',
        method: 'POST',
        body: data,
        headers: {
          'content-type': 'application/json',
        },
      }),
      invalidatesTags: ['common'],
    }),
    getStatusConfig: builder.query({
      query: () => ({
        url: 'getStatusConfig',
        method: 'GET',
      }),
      providesTags: ['common'],
    }),
  }),
});
export const {
  useSolSpecsProjectMutation, useSolSpecsChangeMutation, useGetSummaryLogQuery,
  useSaveSummaryLogMutation, useTopRiskMutation, useRiskIndexMutation, useRiskMatrixMutation,
  useOverAllStatusQuery,
  useSubmitOverAllStatusMutation,
  useGanttChartMutation,
  useVarianceChartTableMutation,
  useVarianceChartMutation,
  useOverAllStatusValueQuery,
  useSavePeopleDelegationMutation,
  useSubmitStatusConfigMutation,
  useGetStatusConfigQuery,
} = createCommonApi;

// My Page Servcies
export const createMyPageApi = createApi({
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  tagTypes: ['mypage'],
  reducerPath: 'createMyPageApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/mypage/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    myPageProgram: builder.mutation({
      query: (data) => ({
        url: 'getMyPageProgram',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mypage'],
    }),
    myPageProject: builder.mutation({
      query: (data) => ({
        url: 'getMyPageProject',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mypage'],
    }),
    myPageChange: builder.mutation({
      query: (data) => ({
        url: 'getMyPageChange',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mypage'],
    }),
    myPageRisk: builder.mutation({
      query: (data) => ({
        url: 'getMyPageRisk',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mypage'],
    }),
    myPageRiskMitigation: builder.mutation({
      query: (data) => ({
        url: 'getMyPageRiskMitigation',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mypage'],
    }),
    myPageBenefit: builder.mutation({
      query: (data) => ({
        url: 'getMyPageBenefit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mypage'],
    }),
    myPageBenefitTrackingPoint: builder.mutation({
      query: (data) => ({
        url: 'getMyPageBenefitTrackingPoint',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mypage'],
    }),
    myPageEpic: builder.mutation({
      query: (data) => ({
        url: 'getMyPageEpic',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mypage'],
    }),
    myPageApplication: builder.mutation({
      query: (data) => ({
        url: 'getMyPageApplication',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mypage'],
    }),
    myPageProcess: builder.mutation({
      query: (data) => ({
        url: 'getMyPageProcess',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mypage'],
    }),
    myPageBusiness: builder.mutation({
      query: (data) => ({
        url: 'getMyPageBusiness',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mypage'],
    }),
    myPageVendor: builder.mutation({
      query: (data) => ({
        url: 'getMyPageVendor',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mypage'],
    }),
    myPageCustomer: builder.mutation({
      query: (data) => ({
        url: 'getMyPageCustomer',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mypage'],
    }),
    myPageEpicTask: builder.mutation({
      query: (data) => ({
        url: 'getMyPageEpicTask',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mypage'],
    }),
    myPageRequirement: builder.mutation({
      query: (data) => ({
        url: 'getMyPageRequirement',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mypage'],
    }),
    myPageProduct: builder.mutation({
      query: (data) => ({
        url: 'getMyPageProduct',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['mypage'],
    }),
  }),
});
export const {
  useMyPageProgramMutation,
  useMyPageProjectMutation,
  useMyPageChangeMutation,
  useMyPageRiskMutation,
  useMyPageRiskMitigationMutation,
  useMyPageBenefitMutation,
  useMyPageBenefitTrackingPointMutation,
  useMyPageEpicMutation,
  useMyPageApplicationMutation,
  useMyPageProcessMutation,
  useMyPageBusinessMutation,
  useMyPageVendorMutation,
  useMyPageCustomerMutation,
  useMyPageEpicTaskMutation,
  useMyPageRequirementMutation,
  useMyPageProductMutation,
} = createMyPageApi;

// Requirement Services
export const createRequirementApi = createApi({
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  tagTypes: ['requirement'],
  reducerPath: 'createRequirementApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/requirement/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getReqirementJson: builder.query({
      query: () => ({
        url: 'extMtDt',
        method: 'GET',
      }),
      providesTags: ['requirement'],
    }),
    checkRequirementName: builder.mutation({
      query: (name) => ({
        url: 'getByName',
        method: 'POST',
        body: name,
      }),
      providesTags: ['requirement'],
    }),
    submitRequirement: builder.mutation({
      query: (data) => ({
        url: 'submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['requirement'],
    }),
    fetchAllRequirement: builder.query({
      query: (id) => ({
        url: 'fetchAll',
        method: 'GET',
      }),
      providesTags: ['requirement'],
    }),
    getRequirementById: builder.mutation({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['requirement'],
    }),
    getRequirementByStatus: builder.query({
      query: (status) => ({
        url: 'getbystatus',
        method: 'POST',
        body: status,
      }),
      invalidatesTags: ['requirement'],
    }),
    deleteReq: builder.mutation({
      query: (data) => ({
        url: 'deleteReq',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['requirement'],
    }),
  }),
});
export const {
  useGetReqirementJsonQuery,
  useGetRequirementByIdMutation,
  useFetchAllRequirementQuery,
  useCheckRequirementNameMutation,
  useSubmitRequirementMutation,
  useGetRequirementByStatusQuery,
  useDeleteReqMutation,
} = createRequirementApi;

// Business Services
export const createProductApi = createApi({
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  tagTypes: ['product', 'product-twin'],
  reducerPath: 'createProductApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/product/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProductJson: builder.query({
      query: () => ({
        url: 'extMtDt',
        method: 'GET',
      }),
      providesTags: ['product'],
    }),
    checkProductName: builder.mutation({
      query: (name) => ({
        url: 'getByName',
        method: 'POST',
        body: name,
      }),
      providesTags: ['product'],
    }),
    checkProductCode: builder.mutation({
      query: (code) => ({
        url: 'getByCode',
        method: 'POST',
        body: code,
      }),
      providesTags: ['product'],
    }),
    submitProduct: builder.mutation({
      query: (data) => ({
        url: 'submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['product'],
    }),
    fetchAllProduct: builder.query({
      query: (id) => ({
        url: 'fetchAll',
        method: 'GET',
      }),
      providesTags: ['product'],
    }),
    getProductById: builder.mutation({
      query: (id) => ({
        url: 'getById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['product'],
    }),
    getProductByStatus: builder.query({
      query: (status) => ({
        url: 'getbystatus',
        method: 'POST',
        body: status,
      }),
      invalidatesTags: ['product'],
    }),
    productTwinById: builder.mutation({
      query: (id) => ({
        url: 'productTwinById',
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['product-twin'],
    }),
    submitProductTwin: builder.mutation({
      query: (data) => ({
        url: 'submitProductTwin',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['product-twin'],
    }),
  }),
});
export const {
  useGetProductJsonQuery,
  useGetProductByIdMutation,
  useFetchAllProductQuery,
  useCheckProductNameMutation,
  useSubmitProductMutation,
  useGetProductByStatusQuery,
  useProductTwinByIdMutation,
  useSubmitProductTwinMutation,
  useCheckProductCodeMutation,
} = createProductApi;

// Issue Services
export const createIssueApi = createApi({
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  tagTypes: ['issue'],
  reducerPath: 'createIssueApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/issue/`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const userData = localStorage.getItem('authData');
      const user = JSON.parse(userData);
      if (user && endpoint) {
        headers.set('Authorization', `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    submitIssue: builder.mutation({
      query: (data) => ({
        url: 'issue-submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['issue'],
    }),
    submitImpact: builder.mutation({
      query: (data) => ({
        url: 'impact-submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['issue'],
    }),
    submitCorrection: builder.mutation({
      query: (data) => ({
        url: 'correction-submit',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['issue'],
    }),
    getIssueJson: builder.query({
      query: () => ({
        url: 'issue-extMtDt',
        method: 'GET',
      }),
      providesTags: ['issue'],
    }),
    getImpactJson: builder.query({
      query: () => ({
        url: 'impact-extMtDt',
        method: 'GET',
      }),
      providesTags: ['issue'],
    }),
    getCorrectionJson: builder.query({
      query: () => ({
        url: 'correction-extMtDt',
        method: 'GET',
      }),
      providesTags: ['issue'],
    }),
    getIssueRelatedObject: builder.mutation({
      query: (payload) => ({
        url: 'getIssueRelatedObject',
        method: 'POST',
        body: payload,
      }),
      providesTags: ['issue'],
    }),
    getAllIssue: builder.mutation({
      query: (payload) => ({
        url: 'getAllIssue',
        method: 'POST',
        body: payload,
      }),
      providesTags: ['issue'],
    }),
    getAllImpact: builder.mutation({
      query: (payload) => ({
        url: 'getAllImpact',
        method: 'POST',
        body: payload,
      }),
      providesTags: ['issue'],
    }),
    getAllCorrection: builder.mutation({
      query: (payload) => ({
        url: 'getAllCorrection',
        method: 'POST',
        body: payload,
      }),
      providesTags: ['issue'],
    }),
    getIssueById: builder.mutation({
      query: (id) => ({
        url: 'issueById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['issue'],
    }),
    getImpactById: builder.mutation({
      query: (id) => ({
        url: 'impactById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['issue'],
    }),
    getCorrectionById: builder.mutation({
      query: (id) => ({
        url: 'correctionById',
        method: 'POST',
        body: id,
      }),
      providesTags: ['issue'],
    }),
    checkIssueName: builder.mutation({
      query: (name) => ({
        url: 'checkIssueName',
        method: 'POST',
        body: name,
      }),
      providesTags: ['issue'],
    }),
    checkImpactName: builder.mutation({
      query: (name) => ({
        url: 'checkImpactName',
        method: 'POST',
        body: name,
      }),
      providesTags: ['issue'],
    }),
    checkCorrectionName: builder.mutation({
      query: (name) => ({
        url: 'checkCorrectionName',
        method: 'POST',
        body: name,
      }),
      providesTags: ['issue'],
    }),
    getMyIssue: builder.mutation({
      query: (id) => ({
        url: 'getMyIssue',
        method: 'POST',
        body: id,
      }),
      providesTags: ['issue'],
    }),
    getMyCorrection: builder.mutation({
      query: (id) => ({
        url: 'getMyCorrection',
        method: 'POST',
        body: id,
      }),
      providesTags: ['issue'],
    }),
  }),
});
export const {
  useSubmitIssueMutation,
  useGetIssueJsonQuery,
  useGetImpactJsonQuery,
  useGetCorrectionJsonQuery,
  useGetIssueRelatedObjectMutation,
  useGetAllIssueMutation,
  useGetAllImpactMutation,
  useGetAllCorrectionMutation,
  useSubmitImpactMutation,
  useSubmitCorrectionMutation,
  useGetIssueByIdMutation,
  useGetImpactByIdMutation,
  useGetCorrectionByIdMutation,
  useCheckIssueNameMutation,
  useCheckImpactNameMutation,
  useCheckCorrectionNameMutation,
  useGetMyIssueMutation,
  useGetMyCorrectionMutation,
} = createIssueApi;
